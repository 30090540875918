import { Galleria } from "primereact/galleria";
import styles from "./PlatformBanner.module.css";
import { useEffect, useState } from "react";

const imagesA = [
  {
    src: "a/office.jpg",
  },
  {
    src: "a/1.jpg",
  },
  {
    src: "a/2.jpg",
  },
  {
    src: "a/3.jpg",
  },
];

const imagesB = [
  {
    src: "b/construction.jpg",
  },
  {
    src: "b/4.jpg",
  },
  {
    src: "b/5.jpg",
  },
  {
    src: "b/6.jpg",
  },
];

export default function PlatformBanner({ imageSetId }) {
    const [imageSet, setImageSet] = useState([]);
    
    useEffect(() => {
        if (imageSetId === "a") {
            setImageSet(imagesA);
        }else if (imageSetId === "b") {
            setImageSet(imagesB);
        }
        console.log(imageSetId);
    }, [imageSetId]);

  const galleriaItem = (item) => {
    return (
      <img
        src={require(`./images/${item.src}`)}
        alt="Banner Image"
        className={styles.galleriaImage}
      />
    );
  };

  return (
    <div className={styles.container}>
      <Galleria
        value={imageSet}
        item={galleriaItem}
        showItemNavigators
        showItemNavigatorsOnHover
        
        showThumbnails={false}
        circular
        autoPlay={true}
        transitionInterval={5000}
        className={styles.galleria}
      />
    </div>
  );
}
