import React, { useEffect, useState } from "react";
import ProfileMenu from "../../components/ProfileMenu/ProfileMenu";
import { Menu } from "primereact/menu";
import { Avatar } from "primereact/avatar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Outlet, Navigate } from "react-router-dom";
import styles from "./ProfilePage.module.css";
import useAuthService from "../../service/useAuthService";
import useAdminService from "../../service/useAdminService";
import { useGetQuery } from "../../store/slices/apiSlice";

export default function ProfilePage() {
  const [user, setUser] = useState("ФИО");
  const [userStatus, setUserStatus] = useState("Бизнес аккаунт");
  const [items, setItems] = useState([]);
  const navigate = useNavigate();
  
  useAuthService(true);
  const { isAdmin } = useAdminService();

  /*---api--- */
  const profileEP = useSelector((state) => state.endpoints.profile);

  let { data: remotePersonalData } = useGetQuery(`${profileEP}/personal-data`, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (remotePersonalData) {
      setUser(`${ remotePersonalData.lastName } ${ remotePersonalData.firstName }`);
      switch (remotePersonalData.participantStatus) {
        case 'VIP':
          setUserStatus("Вип");
          break;
        case 'BIZ':
          setUserStatus("Бизнес");
          break;
        case 'NONE':
          setUserStatus("Пользователь");
          break;
        default:
          setUserStatus("Пользователь*");
      }
    }
  }, [remotePersonalData]);

  // Обновление элементов меню при изменении isAdmin или данных пользователя
  useEffect(() => {
    const menuLinks = [
      {
        label: "Личная",
        command: () => navigate("./personal"),
      },
      {
        label: "Контактная",
        command: () => navigate("./contacts"),
      },
      {
        label: "Профессиональная",
        command: () => navigate("./professional"),
      },
      {
        label: "Мои события",
        command: () => navigate("./events"),
      },
      {
        label: "Мои заказы",
        command: () => navigate("./orders"),
      },
    ];

    // Добавляем пункт меню для администратора, если isAdmin равно true
    console.log(isAdmin)
    if (isAdmin) {
      menuLinks.push({
        label: "Администрирование",
        command: () => navigate("/administration"),
      });
    }

    const updatedItems = [
      {
        template: () => {
          return (
            <div
            
              className={ styles.info }>
              <div>
                <Avatar
                  onClick={()=> navigate("./personal")}
                  className={ styles.menu_avatar } shape="circle">
                  <i
                    className="pi pi-user"
                    style={{ fontSize: "1.5rem", color: "#fff" }}
                  />
                </Avatar>
              </div>

              <div className={styles.user_name}>
                <h3>{user}</h3>
                <p> {userStatus}</p>
              </div>
            </div>
          );
        },
      },
      {
        separator: true,
      },
      {
        items: menuLinks,
      },
    ];

    setItems(updatedItems);
  }, [isAdmin, user, userStatus, navigate]);

  return (
    <>
      {window.location.pathname === "/profile" ? (
        <Navigate to="/profile/orders" replace={true} />
      ) : null}
      <ProfileMenu user={user} status={userStatus} model={"user"} />
      <div className={styles.content}>
        <Menu model={items} className={styles.side_menu} />
        <Outlet />
      </div>
    </>
  );
}
