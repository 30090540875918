import React from "react";
import telegram from "../../images/telegram-white.svg";
import styles from "./SocialButtons.module.css";

export default function SocialButtons() {
  return (
    <div className={styles.container}>
      <div
        className={styles.btn_dark}
        onClick={() => window.open("https://t.me/gradforum_bot", "_blank")}
      >
        <img src={telegram} alt="Telegram bot" />
        <span className={styles.btn_text}>Поддержка</span>
      </div>
      <div
        className={styles.btn_light}
        onClick={() => window.open("https://t.me/gradforum", "_blank")}
      >
        <img src={telegram} alt="Telegram" />
        <span className={styles.btn_text}>Телеграм</span>
      </div>
    </div>
  );
}
