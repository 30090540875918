import React from "react";
import { Button } from "primereact/button";
import UsersDatatable from "../UsersDatatable/UsersDatatable";
import { useNavigate } from "react-router";
import styles from "./UsersDatatableCard.module.css";
import { useDownloadUsersExcelMutation } from "../../store/slices/apiSlice";

export default function UsersDatatableCard() {
  const navigate = useNavigate();
  const [downloadUsers] = useDownloadUsersExcelMutation();

  const handleDownload = async (fileName, allUsers) => {
    try {
      const response = await downloadUsers(allUsers).unwrap();

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(`Ошибка при скачивании программы: ${error}`);
    }
  };
  return (
    <div className={styles.card}>
      <div className={styles.row}>
        <h2>Пользователи</h2>
        <div className={styles.btns_row}>
        <Button
          label="Новый пользователь"
          icon="pi pi-plus"
          className={styles.custom_btn}
          onClick={() => {
            navigate("./create");
          }}
        />
          <Button
            label="Билеты"
            icon="pi pi-file-excel"
            className={styles.custom_btn}
            onClick={() => {
              handleDownload("Пользователи Градостроительного форума.xlsx", false);
            }}
          />
          <Button
            label="Все"
            icon="pi pi-download"
            className={styles.custom_btn}
            onClick={() => {
              handleDownload("Пользователи Градостроительного форума.xlsx", true);
            }}
          />
        </div>
      </div>
      <UsersDatatable />
    </div>
  );
}
