import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import UserOrderTemplate from "../../components/UserOrderTemplate/UserOrderTemplate";
import { useParams, useNavigate } from "react-router-dom";
import getStatusLabel from "../../hooks/useCheckStatus";
import {
  useGetAdminOrderByIdQuery,
  useStartProcessingOrderMutation,
  useApproveOrderMutation,
  useRejectOrderMutation,
} from "../../store/slices/apiSlice";
import OrderFileTable from "../../components/OrderFileTable/OrderFileTable";
import styles from "./OrderDetails.module.css";
import { _ticker } from "gsap/gsap-core";


export default function OrderDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const toast = useRef(null);


  
  // Получаем данные заказа с сервера
  const { data: orderData, error, isLoading } = useGetAdminOrderByIdQuery(params.orderId);
  const [transformedOrders, setTransformedOrders] = useState([]);

  // Создаем мутации
  const [startProcessingOrder] = useStartProcessingOrderMutation();
  const [approveOrder] = useApproveOrderMutation();
  const [rejectOrder] = useRejectOrderMutation();

  const [totalPrice, setTotalPrice] = useState(0);
  useEffect(() => {
    if (orderData) {
      // Calculate total price of all tickets
      const total = orderData.tickets.reduce((acc, ticket) => acc + parseFloat(ticket.price || 0), 0);
      setTotalPrice(total);
    }
  }, [orderData]);
  

  useEffect(() => {
    if (orderData) {
      // Трансформируем данные для UserOrderTemplate
      const transformedData = orderData.tickets.map(ticket => ({
        tariff: ticket.plan === "VIP" ? "VIP" : "Бизнес",
        company: ticket.user.company || "",
        position: ticket.user.jobTitle || "",
        firstName: ticket.user.firstName || "",
        middleName: ticket.user.secondName || "",
        lastName: ticket.user.lastName || "",
        phone: ticket.user.phoneNumber || "",
        email: ticket.user.email || "",
        inn: ticket.user.companyInn || "",
        promo: ticket.promoCode || "",
        price: ticket.price || null,
      }));
      setTransformedOrders(transformedData);

      if (orderData.status === "SENT") {
        const startProcessing = async () => {
          try {
            await startProcessingOrder(orderData.id).unwrap();
            toast.current.show({
              severity: "info",
              summary: "Обработка",
              detail: "Начата обработка заказа",
              life: 3000,
            });
          } catch (error) {
            toast.current.show({
              severity: "error",
              summary: "Ошибка",
              detail: "Ошибка при начале обработки заказа",
              life: 3000,
            });
            console.error("Ошибка при начале обработки заказа:", error);
          }
        };
        startProcessing();
      }
    }
  }, [orderData, startProcessingOrder]);

  const handleApprove = async () => {
    try {
      await approveOrder(orderData.id).unwrap();
      toast.current.show({
        severity: "success",
        summary: "Успех",
        detail: "Заказ принят",
        life: 3000,
      });
      setTimeout(() => {
        navigate(-1); // Вернуться на предыдущую страницу
      }, 3000)
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Ошибка при принятии заказа",
        life: 3000,
      });
      console.error("Ошибка при принятии заказа:", error);
    }
  };

  const handleReject = async () => {
    try {
      await rejectOrder(orderData.id).unwrap();
      toast.current.show({
        severity: "success",
        summary: "Успех",
        detail: "Заказ отклонен",
        life: 3000,
      });
      navigate(-1); // Вернуться на предыдущую страницу
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Ошибка при отклонении заказа",
        life: 3000,
      });
      console.error("Ошибка при отклонении заказа:", error);
    }
  };

  if (isLoading) return <p>Загрузка...</p>;
  if (error) return <p>Ошибка загрузки заказа.</p>;

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, ''); // Убираем все нецифровые символы
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/); // Разбиваем на группы
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
    }
    return phoneNumber; // Возвращаем исходный номер, если формат не подходит
  };

  return (
    <div className={styles.card}>
      <Toast ref={toast} />
      <div>
        <Button
          label="Назад"
          icon="pi pi-angle-left"
          className={styles.go_back_btn}
          onClick={() => navigate(-1)}
        />
      </div>

      <div className={styles.content}>
        <div className={styles.info}>
          <div className={styles.info_labels}>
            <h3>Заказ от {new Date(orderData.orderDate).toLocaleDateString()}</h3>
            <h3 className={styles.numb}>№{orderData.id}</h3>
        </div>
          { getStatusLabel(orderData.status) }
        </div>
        <div className={styles.customer_info}>
          <h3>
            {orderData.customer.lastName} {orderData.customer.firstName} {orderData.customer.secondName}
          </h3>
          <a href={`tel:${orderData.customer.phoneNumber}`} className={styles.numb}>
            {formatPhoneNumber(orderData.customer.phoneNumber)}
          </a>
          <p>{orderData.customer.company}</p>
          <p>ИНН: {orderData.customer.companyInn}</p>
        </div>

        <p className={styles.ticket_type}>
          Тип билета: <span>{orderData.tickets.length > 1 ? "Корпоративный заказ" : "Персональный билет"}</span>
        </p>
        {transformedOrders.map((transformedOrder, index) => (
          <UserOrderTemplate key={index} user={transformedOrder} editDisabled={true} disablePromo={true} />
        ))}
      </div>
      {totalPrice != 0 ? (
        <div className={styles.totalPriceContainer}>
          <h3>Всего: {totalPrice} ₽</h3>
        </div>
      ) : (
        ""
      )}
      <OrderFileTable orderId={ orderData.id } editMode={ true } />
      {orderData.status === "SENT" || orderData.status === "PROCESSING" ? (
        <div className={styles.row}>
          <Button label="Принять" severity="success" onClick={handleApprove} />
          <Button label="Отклонить" severity="danger" onClick={handleReject} />
        </div>
      ) : null}
    </div>
  );
}
