import React from 'react';
import styles from './Loader.module.css';
//import { ProgressSpinner } from 'primereact/progressspinner';
import CircularProgress from '@mui/material/CircularProgress';


export default function Loader() {
    return (
        <div className={styles.container}>
            <CircularProgress size='6vw' />
        </div>
    )
}
