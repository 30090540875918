import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useAuthService = (redirect = false) => {
  const navigate = useNavigate();

  const isAuthenticated = () => {
    const accessToken = localStorage.getItem('accessToken');
    return !!accessToken && accessToken !== 'null';
  };

  useEffect(() => {
    if (redirect && !isAuthenticated()) {
      navigate('/login');
    }
  }, [redirect, navigate]);

  return {
    isAuthenticated,
  };
};

export default useAuthService;
