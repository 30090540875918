import React, { useState, useRef } from "react";
import styles from "./RegistrationForm.module.css";
import { useNavigate } from "react-router-dom";

import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

import { useRegisterUserMutation } from "../../store/slices/apiSlice";
import { useGetCodeMutation } from "../../store/slices/apiSlice";
import ConfirmCode from "../ConfirmCode/ConfirmCode";
import Loader from "../Loader/Loader";
import Error from "../Error/Error";


function RegistrationForm() {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [isFilled, setIsFilled] = useState(false);

  const [registerUser, { isLoading: registrationLoading, error: errorData }] = useRegisterUserMutation();
  const [getCode] = useGetCodeMutation();

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    position: "",
    company: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    position: "",
    company: "",
    phone: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  function validation() {
    const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
    let x = {}

    for (let key in formErrors) {
      let value = form[key];

      if (key === 'phone' && value.length < 10) {
        x[key] = 'Некорректное значение';
      } else if (key === 'email') {
        if (!EMAIL_REGEXP.test(value)) x[key] = 'Некорректное значение';
      } else if (key !== 'phone' && key !== 'email' && value.length < 2) {
        x[key] = 'Некорректное значение';
      }
    }
    setFormErrors({ ...x });
    return Object.values(x).filter(err => err !== '')
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errorsAmount = validation();

    if (errorsAmount.length === 0) {
      if (form.phone.length !== 11) form.phone = '7' + form.phone;

      const response = await registerUser({
        email: form.email,
        phoneNumber: form.phone,
        firstName: form.firstName,
        lastName: form.lastName,
        company: form.company,
        jobTitle: form.position,
      });
      console.log('-----register response', response);

      if (!response.error) {
        const result = await getCode(form.phone);
        console.log('-----code result', result);
        setIsFilled(true);
      }
    }
  };


  if (registrationLoading) return (<Loader />)
  else if (errorData) return (<Error errorData={errorData} />)
  else return (
    <div className={styles.card}>
      <h1>Регистрация</h1>
      <h3>Для компаний из России</h3>

      {!isFilled ?
        <form onSubmit={handleSubmit} className={styles.reg_form}>
          <div className={styles.column}>
            <div className={styles.line}>
              <div className={styles.wrapper}>
                <label htmlFor="firstName">Имя</label>
                <InputText
                  id="firstName"
                  name="firstName"
                  value={form.firstName}
                  onChange={handleChange}
                />
                {formErrors.firstName ? <p className={styles.error}>{formErrors.firstName}</p> : null}
              </div>

              <div className={styles.wrapper}>
                <label htmlFor="lastName">Фамилия</label>
                <InputText
                  id="lastName"
                  name="lastName"
                  value={form.lastName}
                  onChange={handleChange}
                />
                {formErrors.lastName ? <p className={styles.error}>{formErrors.lastName}</p> : null}
              </div>

            </div>
            <div className={styles.line}>
              <div className={styles.wrapper}>
                <label htmlFor="position">Должность</label>
                <InputText
                  id="position"
                  name="position"
                  value={form.position}
                  onChange={handleChange}
                />
                {formErrors.position ? <p className={styles.error}>{formErrors.position}</p> : null}
              </div>

              <div className={styles.wrapper}>
                <label htmlFor="company">Компания</label>
                <InputText
                  id="company"
                  name="company"
                  value={form.company}
                  onChange={handleChange}
                  className="company-input"
                />
                {formErrors.company ? <p className={styles.error}>{formErrors.company}</p> : null}
              </div>

            </div>
            <div className={styles.line}>
              <div className={styles.wrapper}>
                <label htmlFor="phone">Номер телефона</label>
                <InputMask
                  id="phone"
                  name="phone"
                  mask="+7 (999) 999-99-99"
                  value={form.phone}
                  onChange={handleChange}
                  unmask
                />
                {formErrors.phone ? <p className={styles.error}>{formErrors.phone}</p> : null}
              </div>

              <div className={styles.wrapper}>
                <label htmlFor="email">Email</label>
                <InputText
                  id="email"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                />
                {formErrors.email ? <p className={styles.error}>{formErrors.email}</p> : null}
              </div>

            </div>
            {/* <div className={styles.line}>
              <div className={styles.wrapper}>
                <label htmlFor="password">Придумайте пароль</label>
                <Password
                  id="password"
                  name="password"
                  value={form.password}
                  onChange={handleChange}
                  toggleMask
                  promptLabel="Введите пароль"
                  weakLabel="Слабый"
                  mediumLabel="Средний"
                  strongLabel="Сложный"
                  inputStyle={{ width: "100%" }}
                />
              </div>
              <div className={styles.wrapper}>
                <label htmlFor="confirmPassword">Повторите пароль</label>
                <Password
                  id="confirmPassword"
                  name="confirmPassword"
                  value={form.confirmPassword}
                  onChange={handleChange}
                  toggleMask
                  promptLabel="Введите пароль"
                  weakLabel="Слабый"
                  mediumLabel="Средний"
                  strongLabel="Сложный"
                  inputStyle={{ width: "100%" }}
                />
              </div>
            </div> */}
            {/*<div className={styles.wrapper_cover}>
            <label htmlFor="socialLink">Ссылка на соцсети</label>
            <InputText
              id="socialLink"
              name="socialLink"
              value={form.socialLink}
              onChange={handleChange}
            />
  </div>*/}
            <div className={styles.btn_wrapper}>
              <Button label="Зарегистрироваться" className={styles.custom_btn} />
            </div>
            <Toast ref={toast} position="top-center" />
            <div className={styles.register_link}>
              <label>Уже есть аккаунт?</label>
              <Button
                label="Войти"
                icon="pi pi-sign-in"
                iconPos="right"
                link
                className="custom_link"
                onClick={() => navigate("/login")}
              />
            </div>
          </div>
        </form> :
        <ConfirmCode
          phone={form.phone}
        />}
    </div>
  );
}

export default RegistrationForm;
