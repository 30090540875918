import React from "react";
import { useEffect } from "react";
import MainLayout from "../../components/Layout/MainLayout";
import { Speakers } from "./Speakers";
import { useParams } from "react-router-dom";
import styles from "./SpeakerPage.module.css";

export default function SpeakerPage() {
  let params = useParams();
  let speaker = Speakers.find((x) => `${x.name} ${x.surname}` == params.fullname);
  console.log("speaker", speaker, params);

  return (
    <MainLayout>
      {speaker ? (
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.img_wrapper}>
              <img
                src={require(`../../modules/SpeakersGrid/images/${speaker.img}`)}
              />
            </div>
            <div className={styles.mobile_img_wrapper}>
              <img
                src={require(`../../modules/SpeakersGrid/images/${speaker.imgMobile}`)}
              />
            </div>
            <div className={styles.column}>
              <h3>{speaker?.name + " " + speaker?.surname}</h3>
              <p>{speaker?.descr}</p>
              {speaker?.info ? <p>{speaker?.info}</p> : <></>}
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.page_not_found}>
          <h3>Извините, страница не найдена</h3>
        </div>
      )}
    </MainLayout>
  );
}
