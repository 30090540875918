import React, { useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Tag } from "primereact/tag";
import { Chip } from "primereact/chip";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router";
import { useAddEventToFavoritesMutation, useRemoveEventFromFavoritesMutation, useDeleteEventMutation } from "../../store/slices/apiSlice"; // Импортируем хук удаления события
import ExportToIcs from "./EventImport";
import styles from "./EventCard.module.css";
import EventTime from "./EventTime";
import { Link } from "react-router-dom";

export default function EventCard({ event, user = false, admin = false }) {
  const navigate = useNavigate();
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [isFavorite, setIsFavorite] = useState(event.isFavorite);

  const [addEventToFavorites] = useAddEventToFavoritesMutation();
  const [removeEventFromFavorites] = useRemoveEventFromFavoritesMutation();
  const [deleteEvent, { isLoading: isDeleting }] = useDeleteEventMutation(); // Используем хук для удаления события

  const handleFavoriteClick = async () => {
    try {
      if (isFavorite) {
        await removeEventFromFavorites(event.id).unwrap();
        setIsFavorite(false);
      } else {
        await addEventToFavorites(event.id).unwrap();
        setIsFavorite(true);
      }
    } catch (error) {
      console.error("Ошибка при обновлении избранного:", error);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteEvent(event.id).unwrap(); // Отправляем запрос на удаление
      setDeleteVisible(false);
      navigate(0); // Перенаправляем пользователя на календарь после удаления
    } catch (error) {
      console.error("Ошибка при удалении события:", error);
    }
  };

  const dialogFooterContent = (
    <div>
      <Button
        label="Отмена"
        outlined
        className={styles.btn_outlined}
        onClick={() => setDeleteVisible(false)}
      />
      <Button
        label={isDeleting ? "Удаление..." : "Удалить"}
        icon="pi pi-trash"
        className={styles.btn}
        onClick={handleDelete}
        autoFocus
        disabled={isDeleting} // Отключаем кнопку при загрузке
      />
    </div>
  );

    const headerTemplate = (
      <span className={styles.header}>
        <div className={styles.calendar_btn_wrapper}>
          <Button
            icon="pi pi-calendar-plus"
            outlined
            rounded
            text
            className={styles.btn_transparent}
            onClick={() => ExportToIcs(event)}
            tooltip="Добавить в календарь"
          />
        </div>
        <div
          className={styles.bookmark_wrapper}
          style={{ display: event.isFavorite != null ? "flex" : "none" }}
        >
          <Button
            icon={isFavorite ? "pi pi-bookmark-fill" : "pi pi-bookmark"}
            outlined
            rounded
            text
            className={styles.btn_transparent}
            tooltip={isFavorite ? "В избранном" : "Добавить в избранное"}
            onClick={handleFavoriteClick}
          />
        </div>

        <EventTime event={ event } />
        
        <div className={styles.column}>
          <span className={ styles.event_name }>
            { event?.name }
            <span className={ styles.mobile_place }>{ event?.place} </span>
          </span>
          <div className={ styles.tags_row }>
            <Chip key={ event.place }
              label={ `${ event.place }` }
              style={ { fontSize: "0.75rem", backgroundColor: `#e8f3f9`, color: `var(--highlight-dark)`} }/>
            {event.tag?.map((item) => (
              <Chip key={item} label={`#${item}`} style={{ fontSize: "0.75rem" }} />
            ))}
          </div>
        </div>

        <div
          className={styles.btn_wrapper}
          style={{ display: admin ? "flex" : "none" }}
        >
          <Button
            icon="pi pi-pencil"
            outlined
            rounded
            text
            className={styles.btn_transparent_edit}
            onClick={() => navigate(`./edit/${event.id}`)}
            tooltip="Редактировать"
          />
          <Button
            icon="pi pi-trash"
            outlined
            rounded
            text
            className={styles.btn_transparent}
            onClick={() => setDeleteVisible(true)}
            tooltip="Удалить"
          />
          <Dialog
            header="Удалить событие"
            footer={dialogFooterContent}
            visible={deleteVisible}
            onHide={() => setDeleteVisible(false)}
          >
            <p className="m-0">
              Вы уверены, что хотите удалить событие "{event?.name}"?
            </p>
          </Dialog>
        </div>
      </span>
    );

  return (
    <Accordion className="custom-accordion">
      <AccordionTab
        header={ headerTemplate }
        className="custom-accordion-tab"
        contentClassName="custom-accordion-content"
      >
        <div className={styles.content}>
          {/* {event.place ? (
            <div className={styles.column}>
              <h4>Место проведения</h4>
              {event?.place}
            </div>
          ) : null} */}

          { event.descr?.length > 0 && <div className={ styles.column }>
            <h4>Описание</h4>
            <div dangerouslySetInnerHTML={ { __html: event?.descr } } />
          </div> }
          
          {event.moderator?.length ? (
            <div className={styles.column}>
              <h4>Модератор</h4>
              {event.moderator.map((moderator, index) => (
                <p key={index}>{moderator.name}</p>
              ))}
            </div>
          ) : null}

          {event.speakers?.length > 0 || event.minorSpeakers?.length>0 ? (
            <div className={styles.column}>
              <h4>Спикеры</h4>
              {event.speakers.map((speaker, index) => (
                <p key={index}>
                  <Link to={`/speaker/${speaker.name}`} style={{color: "var(--highlight-mid)"}}>
                    {speaker.name}
                  </Link>
                </p>
              )) }
              {event.minorSpeakers?.map((speaker, index) => (
                <p key={index}>
                  {speaker}
                </p>
              ))}
            </div>
          ) : null }
          
          {event.tag?.length ? (
            <div className={styles.desktop_hidden}>
              <div className={styles.column}>
                <h4>Тэги</h4>
                  <div className={ styles.row }>
                    {event.tag?.map((item) => (
                        <Chip key={item} label={`#${item}`} style={{ fontSize: "0.75rem"}} />
                    )) }
                  </div>
              </div>
            </div>
          ) : null }

        </div>
      </AccordionTab>
    </Accordion>
  );
}
