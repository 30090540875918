import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

function ScrollToAnchor() {
    const location = useLocation();
    const lastHash = useRef("");

    useEffect(() => {
        if (location.hash) {
            lastHash.current = location.hash.slice(1);
        }
        console.log(location.hash);

        if (lastHash.current && document.getElementById(lastHash.current)) {
            setTimeout(() => {
                const element = document.getElementById(lastHash.current);
                if (element) {
                    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                    // Выбираем отступ в зависимости от ширины экрана
                    const offset = window.innerWidth <= 1020 ? 70 : 100;
                    window.scrollTo({
                        top: elementPosition - offset,
                        behavior: "smooth",
                    });
                }
                lastHash.current = "";
            }, 200);
        } else {
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 200);
        }
    }, [location]);

    return null;
}

export default ScrollToAnchor;
