import React from "react";
import PersonalInfoCard from "../PersonalInfoCard/PersonalInfoCard";
import PersonalPhotoCard from "../PersonalPhotoCard/PersonalPhotoCard";
import styles from "./PersonalInfoBlock.module.css";

export default function PersonalInfoBlock() {
  return (
    <div className={styles.container}>
      <PersonalInfoCard />
      {/* <PersonalPhotoCard /> */}
    </div>
  );
}
