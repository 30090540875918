import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import styles from "./UserOrderTemplate.module.css";
import { useGetTicketPriceQuery } from "../../store/slices/apiSlice";

export default function UserOrderTemplate({
  user,
  editDisabled,
  disablePromo = false,
  onChange = () => {},
}) {
  const [form, setForm] = useState({
    tariff: user.tariff,
    company: user.company,
    position: user.position,
    firstName: user.firstName,
    middleName: user.middleName,
    lastName: user.lastName,
    phone: user.phone,
    email: user.email,
    inn: user.inn,
    promo: user.promo || "", // Track promo code
  });

  const [selectedTariff, setSelectedTariff] = useState(
    user.tariff ? { name: user.tariff } : null
  );
  const [price, setPrice] = useState(user.price || 0); // Initialize with `user.price` if available

  const tariff = [{ name: "VIP" }, { name: "Бизнес" }];

  const getTariffKey = (name) => {
    if (name === tariff[0].name) return "VIP";
    if (name === tariff[1].name) return "BIZ";
  };

  // Fetch price only if `user.price` is not provided
  const { data: ticketPrice } = useGetTicketPriceQuery(
    {
      plan: getTariffKey(selectedTariff?.name),
      promoCode: form.promo,
    },
    { skip: user.price !== undefined } // Skip fetching price if `user.price` exists
  );

  useEffect(() => {
    setForm(user);
    setSelectedTariff(user.tariff ? { name: user.tariff } : null);
  }, [user]);

  useEffect(() => {
    onChange(form); // Pass initial data to the parent component
  }, []);

  // Update price when ticketPrice data is fetched and `user.price` is not provided
  useEffect(() => {
    if (ticketPrice !== undefined && user.price === undefined) {
      setPrice(ticketPrice);
    }
  }, [ticketPrice, user.price]);

  // Update form state and trigger parent onChange
  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedForm = { ...form, [name]: value };
    setForm(updatedForm);
    onChange(updatedForm);
  };

  // Handle tariff change and trigger price update
  const handleTariffChange = (e) => {
    setSelectedTariff(e.value);
    handleChange({ target: { name: "tariff", value: e.value.name } });
  };

  // Handle promo code change and trigger price update
  const handlePromoChange = (e) => {
    handleChange(e); // Update form state with promo code
  };

  return (
    <div className={styles.order}>
      <div className={styles.item}>
        <div className={styles.column}>
          <label id="tariff">Тариф</label>
          {editDisabled ? (
            <InputText
              aria-describedby="tariff"
              name="tariff"
              value={selectedTariff?.name || ""}
              disabled
            />
          ) : (
            <Dropdown
              aria-labelledby="tariff"
              name="tariff"
              value={selectedTariff}
              onChange={handleTariffChange}
              options={tariff}
              optionLabel="name"
              placeholder="Выберите тариф"
              className={styles.dropdown_text}
            />
          )}
        </div>
      </div>

      <div className={styles.item}>
        <div className="flex flex-column gap-2">
          <label htmlFor="company">Компания</label>
          <InputText
            aria-describedby="company"
            name="company"
            value={form.company}
            onChange={handleChange}
            disabled={editDisabled}
          />
        </div>

        <div className="flex flex-column gap-2">
          <label htmlFor="inn">ИНН компании</label>
          <InputText
            aria-describedby="inn"
            name="inn"
            value={form.inn}
            onChange={handleChange}
            disabled={editDisabled}
          />
        </div>

        <div className="flex flex-column gap-2">
          <label htmlFor="position">Должность</label>
          <InputText
            aria-describedby="position"
            name="position"
            value={form.position}
            onChange={handleChange}
            disabled={editDisabled}
          />
        </div>
      </div>

      <div className={styles.item}>
        <div className="flex flex-column gap-2">
          <label htmlFor="lastName">Фамилия</label>
          <InputText
            aria-describedby="lastName"
            name="lastName"
            value={form.lastName}
            onChange={handleChange}
            disabled={editDisabled}
          />
        </div>

        <div className="flex flex-column gap-2">
          <label htmlFor="firstName">Имя</label>
          <InputText
            aria-describedby="firstName"
            name="firstName"
            value={form.firstName}
            onChange={handleChange}
            disabled={editDisabled}
          />
        </div>

        <div className="flex flex-column gap-2">
          <label htmlFor="middleName">Отчество</label>
          <InputText
            aria-describedby="middleName"
            name="middleName"
            value={form.middleName}
            onChange={handleChange}
            disabled={editDisabled}
          />
        </div>
      </div>

      <div className={styles.item}>
        <div className="flex flex-column gap-2">
          <label htmlFor="phone">Телефон</label>
          <InputMask
            aria-describedby="phone"
            id="phone"
            name="phone"
            mask="+7 (999) 999-99-99"
            placeholder="+7 (999) 111-11-11"
            value={form.phone}
            onChange={handleChange}
            disabled={editDisabled}
          />
        </div>

        <div className="flex flex-column gap-2">
          <label htmlFor="email">Email</label>
          <InputText
            aria-describedby="email"
            name="email"
            value={form.email}
            onChange={handleChange}
            disabled={editDisabled}
          />
        </div>
      </div>

      <div className={ styles.item }>
        {console.log(!disablePromo, form.promo?.length === 0)}
        {(disablePromo && form.promo?.length === 0) ? (
          ""
        ) : (
          <div className="flex flex-column gap-2">
            <label htmlFor="promo">Промокод</label>
            <InputText
              aria-describedby="promo"
              name="promo"
              value={form.promo}
              onChange={handlePromoChange}
              disabled={editDisabled || disablePromo}
            />
          </div>
        )}
        <div className="flex flex-column gap-2">
          <label htmlFor="price">Цена</label>
          <InputText
            aria-describedby="price"
            name="price"
            value={price}
            disabled
          />
        </div>
      </div>
    </div>
  );
}
