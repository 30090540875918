import React from "react";
import styles from "./OrdersBlock.module.css";
import { Button } from "primereact/button";
import UserOrder from "../../components/UserOrder/UserOrder";
import { useNavigate } from "react-router-dom";
import { useGetQuery } from "../../store/slices/apiSlice";
import { useSelector } from "react-redux";

export default function OrdersBlock() {
  const navigate = useNavigate();

  // Получаем URL для endpoint'ов
  const orderEP = useSelector((state) => state.endpoints.userOrders);

  // Выполняем запрос на получение заказов
  const {
    data: ordersData,
    isLoading,
    isError,
  } = useGetQuery(`${orderEP}`, {
    refetchOnMountOrArgChange: true,
  });

  if (isLoading) {
    return <p style={{ margin: "auto" }}>Загрузка заказов...</p>;
  }

  if (isError) {
    return (
      <p style={{ margin: "auto" }}>Произошла ошибка при загрузке заказов</p>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Текущие заказы</h2>
        <Button
          label="Новый заказ"
          icon="pi pi-plus"
          className={styles.order_btn}
          onClick={() => navigate("./create")}
        />
      </div>

      <div className={styles.content}>
        {ordersData?.map((item) => (
          <UserOrder
            orderNumber={item.id} // Используем реальные данные из бэкенда
            orderDate={new Date(item.orderDate).toLocaleDateString()} // Преобразуем дату в удобочитаемый формат
            orderStatus={item.status} // Используем статус из данных
            key={item.orderId}
          />
        ))}
      </div>
    </div>
  );
}
