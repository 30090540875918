import React, { useState, useRef } from "react";
import styles from "./LoginForm.module.css";
import { Link, useNavigate } from "react-router-dom";

import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

import ConfirmCode from "../ConfirmCode/ConfirmCode";
import { useGetCodeMutation } from "../../store/slices/apiSlice";
import Loader from "../Loader/Loader";

function LoginForm() {
  let navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [isFilled, setIsFilled] = useState(false);
  const toast = useRef(null);
  const [getCode, { isLoading: codeLoading }] = useGetCodeMutation();
  const [phoneError, setPhoneError] = useState("");

  const handleProceed = async () => {
    if (phone.length >= 10) {
      let x = phone;
      if (x.length !== 11) x = "7" + x;
      setPhone(x);

      const response = await getCode(x);
      console.log("-----response data", response.data);
      console.log("-----response error", response.error);

      if (!response.error) {
        setIsFilled(true);
      } else {
        setIsFilled(false);
        if (response.error.status === 401)
          setPhoneError("Пользователь не найден");
        if (response.error.status === 400)
          setPhoneError("Активный код уже отправлен");
      }
    } else {
      toast.current.show({
        severity: "info",
        summary: "Справка",
        detail: "Пожалуйста, введите номер телефона.",
        life: 3000,
      });
    }
  };

  if (codeLoading) return <Loader />;
  else
    return (
      <div className={styles.card}>
        <div className={styles.desktop_name}>
          <h1>Вход в личный кабинет</h1>
        </div>

        <div className={styles.mobile_name}>
          <span>Вход</span>
          <span>в личный кабинет</span>
        </div>

        {!isFilled ? (
          <div className={styles.container}>
            <div className={styles.column}>
              <div className={styles.wrapper}>
                <label htmlFor="phone">Номер телефона</label>
                <InputMask
                  id="phone"
                  mask="+7 (999) 999-99-99"
                  value={phone}
                  unmask
                  onChange={(e) => setPhone(e.value)}
                  onFocus={() => setPhoneError(false)}
                />
                {phoneError ? (
                  <p className={styles.error}>{phoneError}</p>
                ) : null}
              </div>
              <Button
                label="Продолжить"
                className={styles.custom_btn}
                onClick={handleProceed}
              />
              <div className={styles.register_link}>
                <Button
                  label="Зарегистрироваться"
                  icon="pi pi-sign-in"
                  iconPos="right"
                  link
                  className="custom_link"
                  onClick={() => navigate("/registration")}
                />
              </div>
              <div className={styles.register_link}>
                <Link
                  className={styles.guide_link}
                  to="/documents/инструкция_для_регистрации_участника_на_форум_через_сайт_gradforum.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Инструкция регистрации участника форума
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <ConfirmCode phone={phone} />
        )}
        <Toast ref={toast} position="top-center" />
      </div>
    );
}

export default LoginForm;
