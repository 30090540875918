import React, { useState } from "react";
import SpeakerCard from "../../components/SpeakerCard/SpeakerCard";
import { Speakers } from "./Speakers";

import backgroundRow1 from "./images/background-1-row.svg";
import backgroundRow2 from "./images/background-2-row.svg";
import backgroundRow3 from "./images/background-3-row.svg";
import backgroundRow4 from "./images/background-4-row.svg";
import backgroundRow5 from "./images/background-5-row.svg";

import backgroundMobileRow1 from "./images/background-mobile-1-row.svg";
import backgroundMobileRow2 from "./images/background-mobile-2-row.svg";
import backgroundMobileRow3 from "./images/background-mobile-3-row.svg";
import backgroundMobileRow4 from "./images/background-mobile-4-row.svg";
import backgroundMobileRow5 from "./images/background-mobile-5-row.svg";
import backgroundMobileRow6 from "./images/background-mobile-6-row.svg";
import backgroundMobileRow7 from "./images/background-mobile-7-row.svg";
import backgroundMobileRow8 from "./images/background-mobile-8-row.svg";
import backgroundMobileRow9 from "./images/background-mobile-9-row.svg";
import backgroundMobileRow10 from "./images/background-mobile-10-row.svg";

import styles from "./SpeakersGrid.module.css";
import { useNavigate } from "react-router-dom";

export default function SpeakersGrid() {
  const [speakers, setSpeakers] = useState(Speakers.slice(0, 1));
  const [hidden, setHidden] = useState(true);
  const navigate = useNavigate();

  const handleHide = () => {
    setSpeakers(Speakers.slice(0, 1));
    setHidden(true);
    navigate("/#speakers");
  };

  const handleShow = () => {
    setSpeakers(Speakers);
    setHidden(false);
  };

  return (
    <div className={styles.box}>
      <div className={styles.container}>
        <div className={styles.background}>
          <img src={backgroundRow1}/>
          <img
              src={backgroundRow2}
              className={hidden ? styles.hidden : styles.shown}
          />
          <img
              src={backgroundRow3}
              className={hidden ? styles.hidden : styles.shown}
          />
          <img
              src={backgroundRow4}
              className={hidden ? styles.hidden : styles.shown}
          />
          <img
              src={backgroundRow5}
              className={hidden ? styles.hidden : styles.shown}
          />
        </div>

        <div className={styles.background_mobile}>
          <img src={backgroundMobileRow1}/>
          <img src={backgroundMobileRow2}/>
          <img
              src={backgroundMobileRow3}
              className={hidden ? styles.hidden : styles.shown}
              style={{marginTop: "-1rem"}}
          />
          <img
              src={backgroundMobileRow4}
              className={hidden ? styles.hidden : styles.shown}
          />
          <img
              src={backgroundMobileRow5}
              className={hidden ? styles.hidden : styles.shown}
              style={{marginTop: "-1rem"}}
          />
          <img
              src={backgroundMobileRow6}
              className={hidden ? styles.hidden : styles.shown}
              style={{marginTop: "2.4rem"}}
          />
          <img
              src={backgroundMobileRow7}
              className={hidden ? styles.hidden : styles.shown}
              style={{marginTop: "1.5rem"}}
          />
          <img
              src={backgroundMobileRow8}
              className={hidden ? styles.hidden : styles.shown}
          />
          <img
              src={backgroundMobileRow9}
              className={hidden ? styles.hidden : styles.shown}
              style={{marginTop: "-1.0rem"}}
          />
          <img
              src={backgroundMobileRow10}
              className={hidden ? styles.hidden : styles.shown}
          />
        </div>

        <div className={styles.speakers_grid}>
          {speakers.map((list) => {
            return (
                <div className={styles.wrapper}>
                  {list.map((row) => {
                  return (
                    <div className={styles.row}>
                      {row.map((speaker) => {
                        return (
                          <SpeakerCard
                            speaker={speaker}
                            img={require(`./images/${speaker.img}`)}
                            imgMobile={require(`./images/${speaker.imgMobile}`)}
                          />
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>

      <div className={hidden ? styles.control_hidden : styles.control_shown}>
        <span
          className={styles.btn}
          style={{ display: hidden ? "block" : "none" }}
          onClick={handleShow}
        >
          Показать всех
        </span>
        <span
          className={styles.btn}
          style={{ display: hidden ? "none" : "block" }}
          onClick={handleHide}
        >
          Свернуть
        </span>
      </div>
    </div>
  );
}
