import { Carousel } from "primereact/carousel";
import styles from "./RoadMap.module.css";
import { useEffect, useState } from "react";

export default function RoadMap() {
  const [pages, setPages] = useState([]);
  const [length] = useState(14);

  const responsiveOptions = [
    {
      breakpoint: "1199px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  useEffect(() => {
    initPages(length);
  }, [length]);

  const initPages = (length) => {
    const arr = [];
    for (let i = 1; i <= length; i++) {
      arr.push(require(`./images/page_${i}.jpg`));
    }
    setPages(arr);
  };

  // Define the template function to render each carousel item
  const pageTemplate = (page) => {
    return (
      <div className={styles.page}>
        <img src={page} alt="Page" className={styles.page_image} />
      </div>
    );
  };

  return (
    <>
      <Carousel
        value={pages}
        numVisible={3}
        numScroll={1}
        circular
        showIndicators
        
        responsiveOptions={responsiveOptions}
        className={styles.carousel}
        itemTemplate={pageTemplate} // Pass the template function here
      />
    </>
  );
}
