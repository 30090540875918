import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

class MainLayout extends React.Component {
  render() {
    return (
      <>
        <Navbar />
        <main>{this.props.children}</main>
        <Footer />
      </>
    );
  }
}
export default MainLayout;
