import React from 'react';
import styles from './Error.module.css';


export default function Error({ errorData }) {
    return (
        <div className={styles.container}>
            <h2>Ошибка {errorData.status}</h2>
            <p>Перезагрузите страницу</p>
        </div>
    )
}
