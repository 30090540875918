import React, { useEffect, useState } from "react";
import UrbanTourProgramCard from "../UrbanTourProgramCard/UrbanTourProgramCard";
import eventsList from "./program.json";
import styles from "./UrbanTourProgram.module.css";

export default function UrbanTourProgram() {
  const [dateVisible, setDateVisible] = useState(eventsList[0].eventDate);
  let visibleDatesList = eventsList.find(
    (item) => item.eventDate == dateVisible
  );

  useEffect(() => {}, [dateVisible]);

  return (
    <div className={styles.container}>
      <div className={styles.dates_line}>
        {eventsList.map((item) => {
          return (
            <span
              className={
                dateVisible == item.eventDate
                  ? styles.date_btn_clicked
                  : styles.date_btn
              }
              onClick={() => setDateVisible(item.eventDate)}
            >
              {item.eventDate}
            </span>
          );
        })}
      </div>
      <div className={styles.events_list}>
        {visibleDatesList.eventsList.map((item) => {
          return (
            <UrbanTourProgramCard
              eventName={item.eventName}
              descr={item.descr}
              img={`/images/${item.img}`}
            />
          );
        })}
      </div>
    </div>
  );
}
