import React, { useEffect, useState } from "react";
import MainLayout from "../../components/Layout/MainLayout";
import EventsCalendar from "../../components/EventsCalendar/EventsCalendar";
import SocialButtons from "../../components/SocialButtons/SocialButtons";
import styles from "./Program.module.css";
import {
  useDownloadProgramExcelMutation,
  useGetQuery,
} from "../../store/slices/apiSlice";
import { useSelector } from "react-redux";
import { url } from "../../store/slices/apiSlice";
import { Speakers } from "../SpeakerPage/Speakers";
import Loader from "../../components/Loader/Loader";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

export default function Program() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [downloadProgram] = useDownloadProgramExcelMutation();
  const profileEP = useSelector((state) => state.endpoints.profile);
  const {
    data: eventsData,
    isLoading,
    isError,
  } = useGetQuery(`${url}/events`, {
    refetchOnMountOrArgChange: true,
  });

  const [events, setEvents] = useState([]);
  const [formatedDates, setFormatedDates] = useState([]);
  const [groupedEvents, setGroupedEvents] = useState();

  useEffect(() => {
    if (eventsData) {
      const formattedEvents = eventsData.map((event) => ({
        id: event.id,
        name: event.title,
        descr: event.content,
        date: new Date(event.dateTime).toLocaleDateString("ru-RU").slice(0, 5),
        time: new Date(event.dateTime).toLocaleTimeString("ru").slice(0, 5),
        finishTime: !event.finishTime
          ? ""
          : new Date(event.finishTime).toLocaleTimeString("ru").slice(0, 5),
        place: event.place,
        tag: event.tags,
        moderator: event.moderators?.map((mod) => ({
          name: mod,
        })),
        speakers: event.speakers?.map((speaker) => ({
          name: speaker,
        })),
        minorSpeakers: event.minorSpeakers,
        isFavorite: event.isFavorite,
      }));

      setEvents(formattedEvents);

      const dates = formattedEvents.map((event) => event.date);
      const uniqueDates = [...new Set(dates)].sort(
        (a, b) => new Date(a) - new Date(b)
      );
      setFormatedDates(uniqueDates);
    }
  }, [eventsData]);


  useEffect(() => {
    let grouped = events.reduce((acc, event) => {
      // Если в аккумуляторе уже есть запись для этой даты, добавляем к ней
      if (!acc[event.date]) {
        acc[event.date] = [];
      }
      acc[event.date].push(event);
      return acc;
    }, {});
    console.log(grouped);
    setGroupedEvents({...grouped});
  }, [events])

  const handleDownload = async (e, fileName) => {
    // Предотвращаем поведение по умолчанию
    e.preventDefault();
    
    try {
      // Получение ответа от API
      const response = await downloadProgram().unwrap();
  
      // Создание URL для скачивания
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const downloadUrl = URL.createObjectURL(blob);
  
      // Создание временной ссылки
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', fileName);
  
      // Симуляция клика по ссылке для скачивания
      document.body.appendChild(link);
      link.click();
  
      // Очищаем URL и удаляем ссылку из DOM
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error(`Ошибка при скачивании программы: ${error}`);
    }
  };
  
  


  return (
    <MainLayout>
      <div className={styles.container} id="program">
        <SocialButtons />

        <div className={styles.column_heading}>
          <span className={styles.heading}>Программа</span>
          <span className={styles.heading}>
            <Button
              type="button"
              className={styles.download_btn}
              onClick={ (e) => handleDownload(e,"Программа Градостроительного форума.xlsx") }>
              <p>Скачать </p>
            <i
              className="pi pi-download"
              style={{ fontSize: "1.5rem", color: "var(--highlight-dark)" }}
            ></i> 
            </Button>
          </span>
        </div>
        <div className={styles.calendar}>
          {isLoading ? (
            <Loader></Loader>
          ) : (
            <EventsCalendar
              dates={formatedDates}
              groupByDate={groupedEvents}
              user={true}
              admin={false}
            />
          )}
        </div>
      </div>
    </MainLayout>
  );
}
