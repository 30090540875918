import React from "react";
import MainLayout from "../../components/Layout/MainLayout";
import SocialButtons from "../../components/SocialButtons/SocialButtons";
import UrbanTourProgram from "../../components/UrbanTourProgram/UrbanTourProgram";
import CardLink from "../../components/CardLink/CardLink";
import devision from "./images/devision.svg";
import background from "./images/background-city.svg";
import backgroundMobile from "./images/backgroud-mobile.svg";
import checkmark from "./images/checkmark.svg";
import styles from "./UrbanTour.module.css";
import { useEffect } from "react";

export default function UrbanTour() {
  const listMark = <img src={checkmark} />;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <MainLayout>
      <div className={styles.container} >
        <SocialButtons />

        <div className={styles.block} id="tour">
          <div className={styles.column}>
            <div className={styles.row}>
              <div className={styles.heading}>
                <span>УРБАН-ТУР</span>
                <span>GRADFORUM</span>
              </div>
              <div className={styles.side_img}>
                <img src={devision}/>
              </div>
            </div>
            <div className={styles.text}>
              <p> Тюмень смело называют столицей российского девелопмента, ведь именно здесь появились такие крупные федеральные застройщики, как «Брусника», «Страна Девелопмент», «ЭНКО» и многие другие!
              </p>
              <p>
                <span className={styles.font_weight_600}>
                  Почему стоит посетить урбан-тур?
                </span>
                <br /> 
                Урбан-тур – это уникальная возможность знакомства с передовыми практиками в области девелоперского продукта, архитектуры, благоустройства и проектирования общественных пространств в г.Тюмени. Участие в туре – отличный шанс для расширения знаний, встреч с ведущими экспертами, а также обмена опытом с коллегами из разных городов нашей страны.
              </p>
              <p>
                <span className={styles.font_weight_600}>Дата:</span> 6 ноября
                <br />
                <span className={styles.font_weight_600}>Длительность:</span> с 10:00 до 18:30
                <br />
                <span className={styles.font_weight_600}>Бесплатно для участников форума</span>
              </p>
              <p>
                <span className={styles.font_weight_600}>
                  Количество участников ограничено.
                </span>{" "}
                Место закрепляется после заполнения формы бронирования.
              </p>
            </div>
            <div className={styles.btn_wrapper}>
              <span
                className={styles.btn}
                onClick={() =>
                  window
                    .open(
                      "https://forms.yandex.ru/u/66d4c5942530c25fcd7069de/",
                      "_blank"
                    )
                    .focus()
                }
              >
                Забронировать место
              </span>
            </div>
          </div>
          <div className={styles.background_img}>
            <img src={background} />
          </div>
          <div className={styles.background_img_mobile}>
            <img src={backgroundMobile} />
          </div>
        </div>

        <div className={styles.container_top_border}>
          <div className={styles.heading}>
            <span>ЧТО ВКЛЮЧЕНО?</span>
          </div>
          <div className={styles.list}>
            <div className={styles.list_item}>
              {listMark}
              <label>Сопровождение экспертами в сфере урбанистики, продукта и продаж
              </label>
            </div>
            <div className={styles.list_item}>
              {listMark}
              <label>Презентация от представителей проектов</label>
            </div>
            <div className={styles.list_item}>
              {listMark}
              <label>Комфортный трансфер по городу</label>
            </div>
            <div className={styles.list_item}>
              {listMark}
              <label>Профессиональная фото- и видеосъемка</label>
            </div>
            <div className={styles.list_item}>
              {listMark}
              <label>Бизнес-ланч в историческом центре города</label>
            </div>
            <div className={styles.list_item}>
              {listMark}
              <label>Горячий таежный чай и вода в дорогу</label>
            </div>
                        <div className={styles.list_item}>
              {listMark}
              <label>Удобная современная система радиогид</label>
            </div>            
          </div>
        </div>

         <div className={styles.container_top_border}>
          <div className={styles.heading}>
            <span>Программа</span>
          </div>
          <div className={styles.program_wrapper}>
            <UrbanTourProgram />
          </div>
        </div> 
  
        <div className={styles.card_container}>
          <div
            className={styles.card}
            onClick={() => window.open("https://t.me/gradforum", "_blank")}
          >
            <CardLink />
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
