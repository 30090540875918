import React, { useEffect, useState, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import PersonalInfo from "../../components/PersonalInfo/PersonalInfo";
import ProfInfo from "../../components/ProfInfo/ProfInfo";
import ContactInfo from "../../components/ContactInfo/ContactInfo";
import { useParams, useNavigate } from "react-router";
import { useGetUserByIdQuery, useUpdateUserMutation } from "../../store/slices/apiSlice";
import styles from "./UserForm.module.css";
import { Toast } from "primereact/toast";

export default function EditUserCard() {
  const navigate = useNavigate();
  const { id } = useParams();
  const toast = useRef(null);

  const { data: userData, isLoading } = useGetUserByIdQuery(id, {
    skip: !id,
  });
  const [updateUser] = useUpdateUserMutation();

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [personalData, setPersonalData] = useState({});
  const [profData, setProfData] = useState({});
  const [contactData, setContactData] = useState({});

  const statuses = [
    { name: "Новый пользователь", code: "NONE" },
    {name: "Пользователь", code: "ACTIVE"},
    { name: "Вип", code: "VIP" },
    { name: "Бизнес", code: "BIZ" },
  ];

  useEffect(() => {
    if (userData) {
      console.log("UserData", userData);
      let birthDate = null;
      if (userData.birthDate) {
        birthDate = new Date(userData.birthDate);
        if (birthDate.getFullYear() === 1)
          birthDate = null;
      }
      setPersonalData({
        firstName: userData.firstName,
        lastName: userData.lastName,
        secondName: userData.secondName,
        birthDate: birthDate,
        gender: userData.gender,
        country: userData.country,
        city: userData.city,
      });
      setProfData({
        company: userData.company,
        companyInn: userData.companyInn,
        companyIndustry: { name: userData.companyIndustry } || null,
        userIndustry: { name: userData.userIndustry } || null,
        jobTitle: userData.jobTitle,
      });
      setContactData({
        email: userData.email,
        phoneNumber: userData.phoneNumber,
        messengers: userData.messengers,
      });
      
      let status = statuses.find(s => s.code === userData.participantStatus);
      if (!status)
        status = { name: "Ошибка", code: "" }
      
      setSelectedStatus(status);
    }
  }, [userData]);

  const handleSave = async () => {
    try {
      const payload = {
        firstName: personalData.firstName || "",
        lastName: personalData.lastName || "",
        secondName: personalData.secondName || "",
        email: contactData.email || "",
        phoneNumber: contactData.phoneNumber || "",
        country: personalData.country || "",
        city: personalData.city || "",
        birthDate: personalData.birthDate ? personalData.birthDate.toISOString() : null,
        gender: personalData.gender || "",
        messengers: contactData.messengers || [],
        company: profData.company || "",
        companyInn: profData.companyInn || "",
        companyIndustry: profData.companyIndustry?.name || "",
        userIndustry: profData.userIndustry?.name || "",
        jobTitle: profData.jobTitle || "",
        participantStatus: selectedStatus.code || "",
      };
  
      await updateUser({ id, body: payload }).unwrap();
      
      toast.current.show({
        severity: "success",
        summary: "Успех",
        detail: "Данные успешно сохранены",
        life: 3000,
      });
  
      setTimeout(() => {
        navigate(-1);
      }, 3000);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Произошла ошибка при сохранении данных",
        life: 3000,
      });
    }
  };
  

  if (isLoading) return <p>Загрузка...</p>;

  return (
    <div className={styles.card}>
      <Toast ref={toast} />
      <div>
        <Button
          label="Назад"
          icon="pi pi-angle-left"
          className={styles.go_back_btn}
          onClick={() => navigate(-1)}
        />
      </div>
      <h2>Редактирование пользователя</h2>

      <div>
        <div className="flex flex-column gap-2">
          <label htmlFor="status">
            Статус <span>*</span>
          </label>
          <Dropdown
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.value)}
            options={statuses}
            optionLabel="name"
            placeholder="Выберете статус"
            className={styles.dropdown_text}
          />
        </div>

        <div className={styles.block}>
          <h3>Личная информация</h3>
          <PersonalInfo value={personalData} onValueChange={setPersonalData} />
        </div>

        <div className={styles.block}>
          <h3>Профессиональная информация</h3>
          <ProfInfo formData={profData} onValueChange={(name, value) => {
            setProfData(prev => ({ ...prev, [name]: value }));
          }} />
        </div>

        <div className={styles.block}>
          <h3>Контактная информация</h3>
          <ContactInfo contactData={contactData} setContactData={setContactData} />
        </div>
      </div>

      <div className={styles.btn_wrapper}>
        <Button label="Сохранить" className={styles.custom_btn} onClick={handleSave} />
      </div>
    </div>
  );
}
