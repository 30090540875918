import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profile: "profile",
  userOrders: "profile/orders",

  //getCode:"/auth/request-code?phoneNumber="
};

const endpointsSlice = createSlice({
  name: "endpoints",
  initialState,
});

export const {} = endpointsSlice.actions;
export default endpointsSlice.reducer;
