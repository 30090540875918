import React, { useState } from "react";
import { Link } from "react-router-dom";
import { NavbarDropdownItems } from "../NavbarDropdown/NavbarDropdownItems";
import telegram from "../../images/telegram.svg";
import user from "../../images/user.svg";
import arrowDown from "../../images/arrowDown.svg";
import styles from "./MobileMenu.module.css";

export default function MobileMenu({setActive}) {
  const [dropdown, setDropdown] = useState(false);
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const SubLinks = () => {
    return (
      <div className={click ? styles.sublinks.clicked : styles.sublinks}>
        <ul onClick={handleClick} className={styles.dropdown_menu}>
          <div className={styles.column}>
            {NavbarDropdownItems.flat().map((item) => {
              return (
                <li>
                  <Link
                    className={item.cName}
                    to={item.path}
                    onClick={ () => {
                      setActive(false);
                      setClick(false);
                     } }
                  >
                    {item.label}
                  </Link>
                </li>
              );
            })}
          </div>
        </ul>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.links}>
        <ul>
          <li onClick={() => setDropdown(!dropdown)}>
            <div className={styles.column}>
              <div className={styles.row}>
                <Link>О форуме</Link>
                <img
                  src={arrowDown}
                  className={dropdown ? styles.arrow_clicked : styles.arrow}
                />
              </div>
              {dropdown && <SubLinks />}
            </div>
          </li>

          <li>
            <Link to="/program">Программа</Link>
          </li>
            <li>
            <Link to="/profile/orders">Купить билет</Link>
          </li>
          <li>
            <Link to="/registration">Регистрация</Link>
          </li>
          <li>
            <Link to="/profile" className={styles.row}>
              <img src={user} />
              Личный кабинет
            </Link>
          </li>
          <li>
            <Link to={"https://t.me/gradforum"} className={styles.row}>
              <img src={telegram} />
              Наш телеграм
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
