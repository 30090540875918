import React, { useState, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import { useNavigate } from "react-router";
import { Toast } from "primereact/toast"; // Для отображения уведомлений
import { useCreateUserMutation } from "../../store/slices/apiSlice"; // Импортируем хук
import styles from "./UserForm.module.css";

export default function CreateUserCard() {
  const navigate = useNavigate();
  const toast = useRef(null); // Для отображения уведомлений

  const [createUser, { isLoading }] = useCreateUserMutation(); // Мутация для создания пользователя

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [patronymic, setPatronymic] = useState("");
  const [gender, setGender] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [position, setPosition] = useState("");

  /*---status--- */
  const [selectedStatus, setSelectedStatus] = useState(null);
  const statuses = [
    { name: "Вип", code: "VIP" },
    { name: "Бизнес", code: "BIZ" },
  ];

  const handleCreateUser = async () => {
    // Данные, которые будут отправлены на сервер
    const userData = {
      firstName: name,
      lastName: lastName,
      secondName: patronymic,
      email: email,
      phoneNumber: phone.replace(/[^\d]/g, ""),
  
      // birthDate: new Date().toISOString(), // Добавить поле даты рождения в форму, если нужно
      gender: gender,
      // messengers: ["telegram"], // Пример, можно добавить поле для выбора мессенджеров
      company: company,
      // companyInn: "1234567890", // Добавить поле ИНН компании, если нужно
      // companyIndustry: "IT", // Пример, можно добавить поле для выбора отрасли
      // userIndustry: "Технологии", // Пример, можно добавить поле для выбора сферы
      jobTitle: position,
      participantStatus: selectedStatus.code,
    };

    try {
      await createUser(userData).unwrap(); // Отправляем запрос на сервер
      toast.current.show({
        severity: "success",
        summary: "Успех",
        detail: "Пользователь успешно создан",
        life: 3000,
      });
      navigate(-1); // Возвращаемся на предыдущую страницу
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Ошибка при создании пользователя",
        life: 3000,
      });
      console.error("Ошибка создания пользователя:", error);
    }
  };

  return (
    <div className={styles.card}>
      <div>
        <Button
          label="Назад"
          icon="pi pi-angle-left"
          className={styles.go_back_btn}
          onClick={() => navigate(-1)}
        />
      </div>
      <h2>Новый пользователь</h2>

      <div className={styles.info}>
        <div className={styles.column}>
          <label htmlFor="status">
            Статус <span>*</span>
          </label>
          <Dropdown
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.value)}
            options={statuses}
            optionLabel="name"
            placeholder="Выберете статус"
            className={styles.dropdown_text}
          />
        </div>

        <div className={styles.item_three}>
          <div className={styles.column}>
            <label htmlFor="last_name">
              Фамилия <span>*</span>
            </label>
            <InputText
              id="last_name"
              aria-describedby="last_name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>

          <div className={styles.column}>
            <label htmlFor="name">
              Имя <span>*</span>
            </label>
            <InputText
              id="name"
              aria-describedby="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className={styles.column}>
            <label htmlFor="patronymic">
              Отчество <span>*</span>
            </label>
            <InputText
              id="patronymic"
              aria-describedby="patronymic"
              value={patronymic}
              onChange={(e) => setPatronymic(e.target.value)}
            />
          </div>
        </div>

        <div className={styles.item_two}>
          <fieldset className={styles.radio}>
            <legend>
              Пол <span>*</span>
            </legend>

            <div className={styles.check}>
              <div className={styles.check_itm}>
                <input
                  className={styles.radio_accent}
                  type="radio"
                  id="men"
                  name="gender"
                  value="Мужской"
                  onChange={(e) => setGender(e.target.value)}
                />
                <label htmlFor="men">Мужской</label>
              </div>

              <div className={styles.check_itm}>
                <input
                  className={styles.radio_accent}
                  type="radio"
                  id="women"
                  name="gender"
                  value="Женский"
                  onChange={(e) => setGender(e.target.value)}
                />
                <label htmlFor="women">Женский</label>
              </div>
            </div>
          </fieldset>
        </div>

        <div className={styles.item_two}>
          <div className="flex flex-column gap-2">
            <label htmlFor="phone">
              Телефон <span>*</span>
            </label>
            <InputMask
              id="phone"
              mask="+7 (999) 999-99-99"
              placeholder="+7 (999) 111-11-11"
              value={phone}
              onChange={(e) => setPhone(e.value)}
            />
          </div>

          <div className="flex flex-column gap-2">
            <label htmlFor="email">
              Email <span>*</span>
            </label>
            <InputText
              id="email"
              aria-describedby="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>

        <div className={styles.item_two}>
          <div className="flex flex-column gap-2">
            <label htmlFor="company">
              Название компании (на русском) <span>*</span>
            </label>
            <InputText
              id="company"
              aria-describedby="company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
          </div>

          <div className="flex flex-column gap-2">
            <label htmlFor="position">
              Должность <span>*</span>
            </label>
            <InputText
              id="position"
              aria-describedby="position"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className={styles.btn_wrapper}>
        <Button
          label="Сохранить"
          className={styles.custom_btn}
          onClick={handleCreateUser}  
          disabled={isLoading} // Блокируем кнопку при загрузке
        />
      </div>

      <Toast ref={toast} /> {/* Добавляем Toast для уведомлений */}
    </div>
  );
}
