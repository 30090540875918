import React, { useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';  // Импорт компонента для загрузки файлов
import { ButtonGroup } from 'primereact/buttongroup';  // Импорт ButtonGroup
import {
  useListFilesAdminQuery,
  useDownloadFileAdminMutation,
  useDeleteFileAdminMutation,
  useUploadFileAdminMutation,
  useListFilesUserQuery,
  useDownloadFileUserMutation
} from '../../store/slices/apiSlice'; // Проверьте правильный путь для импорта

// Импортируем стили из CSS-модуля
import styles from './OrderFileTable.module.css';
import { Toolbar } from 'primereact/toolbar';

export default function OrderFileTable({ orderId, editMode }) {
  const toast = useRef(null);

  // Вызов всех хуков без условий
  const { data: adminFiles, refetch: refetchAdminFiles } = useListFilesAdminQuery(orderId);
  const { data: userFiles, refetch: refetchUserFiles } = useListFilesUserQuery(orderId);

  const [downloadFileAdmin] = useDownloadFileAdminMutation();
  const [downloadFileUser] = useDownloadFileUserMutation();
  const [deleteFileAdmin] = useDeleteFileAdminMutation();
  const [uploadFileAdmin] = useUploadFileAdminMutation(); // Хук для загрузки файла

  // Определяем, какие данные использовать на основе editMode
  const files = editMode ? adminFiles : userFiles;
  const refetch = editMode ? refetchAdminFiles : refetchUserFiles;
  const downloadFile = editMode ? downloadFileAdmin : downloadFileUser;

  // Функция для скачивания файла
  const handleDownload = async (fileName) => {
    try {
      const response = await downloadFile({ orderId, fileName }).unwrap();

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Ошибка скачивания',
        detail: `Не удалось скачать файл ${fileName}.`,
        life: 3000,
      });
    }
  };

  // Функция для удаления файла (доступна только администраторам)
  const handleDelete = async (fileName) => {
    if (!editMode) return; // Проверяем, что админ имеет доступ к удалению
    try {
      await deleteFileAdmin({ orderId, fileName }).unwrap();
      toast.current.show({
        severity: 'success',
        summary: 'Файл удалён',
        detail: `Файл ${fileName} был успешно удалён.`,
        life: 3000,
      });
      refetch(); // Обновление списка файлов после удаления
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Ошибка',
        detail: `Не удалось удалить файл ${fileName}.`,
        life: 3000,
      });
    }
  };

  // Функция для загрузки файла (доступна только администраторам)
  const handleUpload = async ({ files }) => {
    const file = files[0];
    try {
      const formData = new FormData();
      formData.append('file', file);

      // Важно! Убедитесь, что заголовок Content-Type не устанавливается вручную
      await uploadFileAdmin({ orderId, file: formData }).unwrap();

      toast.current.show({
        severity: 'success',
        summary: 'Файл загружен',
        detail: `Файл ${file.name} был успешно загружен.`,
        life: 3000,
      });

      refetch(); // Обновляем список файлов после успешной загрузки
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Ошибка загрузки',
        detail: `Не удалось загрузить файл ${file.name}.`,
        life: 3000,
      });
    }
  };

  // Шаблон для кнопок (группировка с помощью ButtonGroup)
  const actionButtonTemplate = (rowData) => {
    return (
      <div className={styles.buttonGroup}>
        <Button
          icon="pi pi-download"
          className="p-button-rounded p-button-success"
          onClick={() => handleDownload(`${rowData.name}${rowData.extension}`)}
        />
        {editMode && (
          <Button
            icon="pi pi-trash"
            className="p-button-rounded p-button-danger"
            onClick={() => handleDelete(`${rowData.name}${rowData.extension}`)}
          />
        )}
      </div>
    );
  };

  const renderToolbar = () => {
    return (
      <div className="p-toolbar-group-left">
        {editMode && (
          <FileUpload
            className={styles.customFileUpload}
            name="file"
            customUpload
            uploadHandler={handleUpload}
            mode="basic"
            chooseLabel="Загрузить файл"
            auto
            accept="*"
          />
        )}
      </div>
    );
  };

  return (
    <div>
      <Toast ref={toast} />

      {editMode ? <Toolbar start={ renderToolbar }/> : null}
      <DataTable
        value={files}
        selectionMode="single"
        className="p-datatable-sm"
        emptyMessage="К этому заказу еще не прикреплен ни один файл."
        rowClassName={styles.customRowClass}
      >
        {/* Колонка с названием файла */}
        <Column field="name"
          header="Файл"
          className={styles.fileName}
          body={ (row) => `${ row.name }${ row.extension }` } style={ { width: '80%' } } />
        {/* Колонка с кнопками действий (Скачать/Удалить) */}
        <Column body={actionButtonTemplate} />
      </DataTable>
    </div>
  );
}
