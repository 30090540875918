import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./PersonalInfo.module.css"; // Import your CSS module
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";

function PersonalInfo({ value, onValueChange }) {
  const [formData, setFormData] = useState({
    lastName: "",
    firstName: "",
    secondName: "",
    birthDate: new Date(),
    gender: "",
    country: "",
    city: "",
  });

  // Initialize formData
  useEffect(() => {
    console.log("init data");
    if (value) {
      setFormData((prevState) => ({
        ...prevState,
        ...value,
      }));
    }
  }, [value]);

  // Handle input changes
  const handleInputChange = (field, fieldValue) => {
    const updatedData = {
      ...formData,
      [field]: fieldValue,
    };
    setFormData(updatedData);
    onValueChange(updatedData);
  };

  return (
    <div className={styles.info}>
      <div className={styles.item_three}>
        <div className="flex flex-column gap-2">
          <label htmlFor="last_name">
            Фамилия <span>*</span>
          </label>
          <InputText
            id="last_name"
            aria-describedby="last_name"
            value={formData.lastName || ""}
            onChange={(e) => handleInputChange("lastName", e.target.value)}
          />
        </div>

        <div className="flex flex-column gap-2">
          <label htmlFor="firstName">
            Имя <span>*</span>
          </label>
          <InputText
            id="firstName"
            aria-describedby="firstName"
            value={formData.firstName || ""}
            onChange={(e) => handleInputChange("firstName", e.target.value)}
          />
        </div>

        <div className="flex flex-column gap-2">
          <label htmlFor="second_name">
            Отчество <span>*</span>
          </label>
          <InputText
            id="second_name"
            aria-describedby="second_name"
            value={formData.secondName || ""}
            onChange={(e) => handleInputChange("secondName", e.target.value)}
          />
        </div>
      </div>

      <div className={styles.item_two}>
        <div className={styles.label_column}>
          <label htmlFor="birth_date">
            Дата рождения <span>*</span>
          </label>
          <Calendar
            id="birth_date"
            value={formData.birthDate || null}
            dateFormat="dd.mm.yy"
            onChange={(e) => handleInputChange("birthDate", e.value)}
          />
        </div>

        <div className={styles.radio}>
          <label className={styles.inline_label}>
            Пол <span>*</span>
          </label>

          <div className={styles.check}>
            <div className={styles.check_itm}>
              <input
                className={styles.radio_accent}
                type="radio"
                id="men"
                name="gender"
                value="male"
                checked={formData.gender === "male"}
                onChange={(e) => handleInputChange("gender", e.target.value)}
              />
              <label htmlFor="men">Мужской</label>
            </div>

            <div className={styles.check_itm}>
              <input
                className={styles.radio_accent}
                type="radio"
                id="women"
                name="gender"
                value="female"
                checked={formData.gender === "female"}
                onChange={(e) => handleInputChange("gender", e.target.value)}
              />
              <label htmlFor="women">Женский</label>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.item_two}>
        <div className={styles.label_column}>
          <label htmlFor="country">
            Страна проживания <span>*</span>
          </label>
          <InputText
            id="country"
            aria-describedby="country"
            value={formData.country || ""}
            onChange={(e) => handleInputChange("country", e.target.value)}
          />
        </div>

        <div className={styles.label_column}>
          <label htmlFor="city">
            Город проживания <span>*</span>
          </label>
          <InputText
            id="city"
            aria-describedby="city"
            value={formData.city || ""}
            onChange={(e) => handleInputChange("city", e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}

PersonalInfo.propTypes = {
  value: PropTypes.shape({
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    secondName: PropTypes.string,
    birthDate: PropTypes.instanceOf(Date),
    gender: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
  }),
  onValueChange: PropTypes.func.isRequired,
};

PersonalInfo.defaultProps = {
  value: {
    lastName: "",
    firstName: "",
    secondName: "",
    birthDate: null,
    gender: "",
    country: "",
    city: "",
  },
};

export default PersonalInfo;
