import React, { useState } from "react";
import { Menu } from "primereact/menu";
import { Avatar } from "primereact/avatar";
import { Outlet } from "react-router";
import ProfileMenu from "../../components/ProfileMenu/ProfileMenu";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import styles from "./AdminProfilePage.module.css";
import useAdminService from "../../service/useAdminService";
import { useSelector } from "react-redux";
import { useGetQuery } from "../../store/slices/apiSlice";
import { useEffect } from "react";

export default function AdminProfilePage() {
  const [user, setUser] = useState("ФИО");
  const [userStatus, setUserStatus] = useState("Администратор");
  const navigate = useNavigate();

  const {isAdmin, isLoading} =  useAdminService(true);

  const profileEP = useSelector((state) => state.endpoints.profile);

  let { data: remotePersonalData } = useGetQuery(`${profileEP}/personal-data`, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (remotePersonalData) {
      setUser(`${ remotePersonalData.lastName } ${ remotePersonalData.firstName }`);
      switch (remotePersonalData.participantStatus) {
        case 'VIP':
          setUserStatus("Вип" + ". Администратор");
          break;
        case 'BIZ':
          setUserStatus("Бизнес" + ". Администратор");
          break;
        case 'NONE':
          setUserStatus("Администратор");
          break;
        default:
          setUserStatus("Администратор*");
      }
    }
  }, [remotePersonalData]);

  const menuLinks = [
    {
      label: "Личный кабинет",
      command: () => {
        navigate("/profile");
      },
    },
    {
      label: "Пользователи",
      command: () => {
        navigate("./users");
      },
    },
    {
      label: "Календарь событий",
      command: () => {
        navigate("./calendar");
      },
    },
    {
      label: "Заказы",
      command: () => {
        navigate("./orders");
      },
    },
  ];

  let items = [
    {
      template: () => {
        return (
          <div className={styles.info}>
            <Avatar
              onClick={()=> navigate("/profile")}
              className={ styles.menu_avatar } shape="circle">
              <i
                className="pi pi-user"
                style={{ fontSize: "1.5rem", color: "#fff" }}
              />
            </Avatar>
            <div className={styles.user_name}>
              <h3>{user}</h3>
              <p>{userStatus}</p>
            </div>
          </div>
        );
      },
    },
    {
      separator: true,
    },
    {
      items: menuLinks,
    },
  ];

  return (
    <>
      {window.location.pathname == "/administration" ? (
        <Navigate to="/administration/users" replace={true} />
      ) : (
        ""
      )}
      <ProfileMenu user={user} status={userStatus} model={"admin"} />
      <div className={styles.content}>
        <Menu model={items} className={styles.side_menu} />
        <Outlet />
      </div>
    </>
  );
}
