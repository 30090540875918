import React, { useEffect, useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Editor } from "primereact/editor";
import { Chips } from "primereact/chips";
import { Button } from "primereact/button";
import styles from "./EventCardCreateForm.module.css";
import { AutoComplete } from "primereact/autocomplete";
import { Speakers } from "../../modules/SpeakersGrid/Speakers";
import { Toast } from "primereact/toast";

export default function EventCardCreateForm({ initialData = {}, onSave, isLoading }) {
  const toast = useRef(null); // Реф для Toast
  const [formData, setFormData] = useState({
    eventName: "",
    tags: [],
    date: null,
    time: null,
    finishTime: null,
    place: "",
    descr: "",
    speakers: [], // Поле для спикеров
    minorSpeakers:[],
    moderators: [], // Поле для модераторов
  });

  const speakers = Speakers.flat().flat()
    .map((s) => ({...s, fullname: `${s.name} ${s.surname}`}));
  const [filteredSpeakers, setFilteredSpeakers] = useState([])

  useEffect(() => {
    if (initialData && Object.keys(initialData).length > 0) {
      const eventDate = new Date(initialData.dateTime);
      const finishTime = !initialData.finishTime ? null : new Date(initialData.finishTime);
      setFormData({
        eventName: initialData.title || "",
        tags: initialData.tags || [],
        date: eventDate || null,
        time: eventDate || null,
        finishTime: finishTime || null,
        place: initialData.place || "",
        descr: initialData.content || "",
        speakers: initialData.speakers.map((s) => ({
          fullname: s
        })) || [], // Инициализация спикеров
        minorSpeakers: initialData.minorSpeakers || [],
        moderators: initialData.moderators || [], // Инициализация модераторов
      });
    }
  }, [initialData]); // Срабатывает только при изменении initialData

  const handleSave = async () => {
    // Преобразуем дату в формат YYYY-MM-DD
    if (!formData.eventName
      || !formData.date
      || !formData.time
      || !formData.finishTime
      || !formData.place) {
      toast.current.show({
        severity: 'error',
        summary: 'Ошибка',
        detail: 'Все поля должны быть заполнены',
        life: 3000,
      });
    }
    const date = formData.date?.toLocaleDateString('en-CA'); // Этот метод вернет строку в формате YYYY-MM-DD
    
    // Преобразуем время в формат HH:mm
    const time = formData.time?.toLocaleTimeString('en-GB', { hour12: false });
    const finish = formData.finishTime?.toLocaleTimeString('en-GB', { hour12: false });
    
    // Объединяем дату и время
    const dateTime = `${ date }T${ time }`;
    const finishTime = formData.finishTime == null ? null : `${date}T${finish}`;

    const event = {
      title: formData.eventName,
      dateTime, // Сохраняем объединенную строку даты и времени
      finishTime,
      place: formData.place,
      content:formData.descr.trim(),
      participantType: 0, // Не изменяем значение participantType
      tags: formData.tags,
      speakers: formData.speakers.map((s) => s.fullname), // Добавляем спикеров
      minorSpeakers: formData.minorSpeakers,
      moderators: formData.moderators, // Добавляем модераторов
    };
  
    if (event.content === "<p> </p>")
      event.content = "";
    
    onSave(event); // Отправляем объект на сервер
  };
  
  
  const handleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const search = (event) => {
    let _filteredSpeakers;
    if (event.query.trim().length == 0) {
      _filteredSpeakers = [...speakers];
    } else {
      _filteredSpeakers = speakers.filter((s) => {
        let fullname = `${s.fullname}`
        return fullname.toLowerCase().includes(event.query.toLowerCase());
      })
    }

    setFilteredSpeakers(_filteredSpeakers);
  }

  return (
    <div className={ styles.card_form }>
      <Toast ref={toast} />
      <div className={styles.column}>
        <label>Заголовок</label>
        <InputText
          value={formData.eventName}
          onChange={(e) => handleChange("eventName", e.target.value)}
        />
      </div>

      <div className={styles.row}>
        <div className={styles.mobile_row}>
          <div className={styles.date_column}>
            <label>Дата</label>
            <Calendar
              value={formData.date}
              onChange={(e) => handleChange("date", e.value)}
              dateFormat="dd.mm.yy"
            />
          </div>
          <div className={styles.date_column}>
            <label>Начало</label>
            <Calendar
              value={formData.time}
              onChange={(e) => handleChange("time", e.value)}
              timeOnly
              hourFormat="24"
            />
          </div>
          <div className={styles.date_column}>
            <label>Конец</label>
            <Calendar
              value={formData.finishTime}
              onChange={(e) => handleChange("finishTime", e.value)}
              timeOnly
              hourFormat="24"
            />
          </div>
        </div>

        <div className={styles.column}>
          <label>Место</label>
          <InputText
            value={formData.place}
            onChange={(e) => handleChange("place", e.target.value)}
          />
        </div>
      </div>

      <div className={styles.column}>
        <label>Описание</label>
        <Editor
          value={formData.descr}
          onTextChange={(e) => handleChange("descr", e.htmlValue)}
          style={{ height: "320px" }}
        />
      </div>

      <div className={styles.column} style={{ width: "100%" }}>
        <label>Тэги</label>
        <Chips
          value={formData.tags}
          onChange={(e) => handleChange("tags", e.value)}
          className={styles.custom_chip}
        />
      </div>

      <div className={styles.column} style={{ width: "100%" }}>
        <label>Спикеры</label>

        <AutoComplete field="fullname" multiple dropdown
          className={styles.custom_chip}
          value={ formData.speakers }
          suggestions={ filteredSpeakers }
          completeMethod={ search }
          onChange={ (e) => { handleChange("speakers", e.value); console.log(e.value) } }
          itemTemplate={ (i) => `${ i.name } ${ i.surname }` } />
        <Chips
          value={formData.minorSpeakers}
          onChange={(e) => handleChange("minorSpeakers", e.value)}
          className={styles.custom_chip}
        />
      </div>

      <div className={styles.column} style={{ width: "100%" }}>
        <label>Модераторы</label>
        <Chips
          value={formData.moderators}
          onChange={(e) => handleChange("moderators", e.value)}
          className={styles.custom_chip}
        />
      </div>

      <div className={styles.btn_wrapper}>
        <Button
          label={isLoading ? "Сохранение..." : "Сохранить"}
          className={styles.custom_btn}
          onClick={handleSave}
          disabled={isLoading}
        />
      </div>
    </div>
  );
}
