import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "./CreateUserOrder.module.css";
import UserOrderTemplate from "../../components/UserOrderTemplate/UserOrderTemplate";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import {
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useGetOrderByIdQuery,
  useRemoveOrderMutation,
  useGetProfileDataQuery,
} from "../../store/slices/apiSlice"; // Импортируем хук для удаления заказа
import { Dialog } from "primereact/dialog";
import OrderFileTable from "../../components/OrderFileTable/OrderFileTable";

export default function CreateUserOrder() {
  const [selectedTicket, setSelectedTicket] = useState("");
  const [editOrder, setEditOrder] = useState(false);
  const [isEditable, setIsEditable] = useState(true);
  const [dialogVisible, setDialogVisible] = useState(false); // Состояние для диалога
  const navigate = useNavigate();
  const params = useParams();
  const orderId = params.orderId;
  const toast = useRef(null); // Ref для Toast

  const [corpOrder, setCorpOrder] = useState([]);
  const [form, setForm] = useState({
    tariff: "",
    company: "",
    position: "",
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
    email: "",
    inn: "",
    promo: "",
    price: "",
  });

  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    console.log("check");
    // Calculate total price for both corpOrder and form
    const corpTotal = corpOrder.reduce(
      (acc, ticket) => acc + parseFloat(ticket.price || 0),
      0
    );
    const individualPrice = form.price ? parseFloat(form.price) : 0;

    setTotalPrice(corpTotal + individualPrice);
  }, [corpOrder, form]);

  const [createOrder, { isLoading: isCreating }] = useCreateOrderMutation();
  const [updateOrder, { isLoading: isUpdating }] = useUpdateOrderMutation();
  const [removeOrder, { isLoading: isRemoving }] = useRemoveOrderMutation(); // Хук для удаления заказа

  const { data: profileData, isSuccess: profileLoaded } =
    useGetProfileDataQuery(); // Получаем данные профиля
  const {
    data: orderData,
    isLoading,
    isSuccess,
  } = useGetOrderByIdQuery(orderId, {
    skip: !orderId,
  });

  useEffect(() => {
    if (orderId) {
      setEditOrder(true);
    }
  }, [orderId]);

  useEffect(() => {
    if (isSuccess && orderData) {
      if (orderData.status !== "SENT") {
        setIsEditable(false);
      }

      const firstTicket = orderData.tickets[0];
      setSelectedTicket(
        orderData.tickets.length > 1
          ? "Корпоративный заказ"
          : "Персональный билет"
      );
      setForm({
        tariff: firstTicket.plan === "VIP" ? "VIP" : "Бизнес",
        company: firstTicket.company,
        position: firstTicket.jobTitle,
        firstName: firstTicket.firstName,
        middleName: firstTicket.secondName,
        lastName: firstTicket.lastName,
        phone: firstTicket.phoneNumber,
        email: firstTicket.email,
        inn: firstTicket.inn,
        promo: firstTicket.promoCode,
        price: firstTicket.price,
      });

      if (selectedTicket === "Корпоративный заказ") {
        setCorpOrder(
          orderData.tickets.map((ticket) => ({
            tariff: ticket.plan === "VIP" ? "VIP" : "Бизнес",
            company: ticket.company,
            position: ticket.jobTitle,
            firstName: ticket.firstName,
            middleName: ticket.secondName,
            lastName: ticket.lastName,
            phone: ticket.phoneNumber,
            email: ticket.email,
            inn: ticket.inn,
            promo: ticket.promoCode,
            price: ticket.price,
          }))
        );
      }
    }
  }, [orderData, isSuccess, selectedTicket]);

  useEffect(() => {
    if (profileLoaded && !editOrder) {
      // Заполняем форму данными из профиля, если мы создаем новый заказ
      const defaultForm = {
        tariff: "Бизнес", // Можно установить дефолтное значение тарифа
        company: profileData.company || "",
        position: profileData.jobTitle || "",
        firstName: profileData.firstName || "",
        middleName: profileData.secondName || "",
        lastName: profileData.lastName || "",
        phone: profileData.phoneNumber || "",
        email: profileData.email || "",
        inn: profileData.companyInn || "",
      };

      setForm(defaultForm);

      // Если выбран корпоративный заказ, добавляем текущего пользователя в список участников
      if (selectedTicket === "Корпоративный заказ") {
        setCorpOrder([defaultForm]);
      }
    }
  }, [profileLoaded, profileData, editOrder, selectedTicket]);

  const handleUserFormChange = (updatedForm) => {
    setForm(updatedForm);
  };

  const handleCorpOrderChange = (index, updatedData) => {
    const updatedCorpOrder = corpOrder.map((item, idx) =>
      idx === index ? updatedData : item
    );
    setCorpOrder(updatedCorpOrder);
  };

  const formatPhoneNumber = (phone) => {
    return phone.replace(/[^\d]/g, "");
  };

  const mapToCreateTicketDto = (data) => {
    console.log(data);
    return {
      Plan: data.tariff == "VIP" ? "VIP" : "BIZ",
      FirstName: data.firstName,
      LastName: data.lastName,
      SecondName: data.middleName,
      Company: data.company,
      JobTitle: data.position,
      PhoneNumber: formatPhoneNumber(data.phone),
      Email: data.email,
      inn: data.inn,
      promoCode: data.promo,
    };
  };

  const handleSubmit = async () => {
    const tickets =
      selectedTicket === "Корпоративный заказ"
        ? corpOrder.map(mapToCreateTicketDto)
        : [mapToCreateTicketDto(form)];
    const orderData = {
      tickets,
    };
    if (tickets.some((t) => t.inn === "")) {
      toast.current.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Заполните ИНН компании у всех участников",
        life: 3000,
      });
      return;
    }
    try {
      if (editOrder) {
        await updateOrder({ orderId, orderData }).unwrap();
        toast.current.show({
          severity: "success",
          summary: "Успех",
          detail: "Заказ успешно обновлен",
          life: 3000,
        });
        setTimeout(() => {
          navigate("/profile/orders");
        }, 3000);
      } else {
        await createOrder(orderData).unwrap();
        toast.current.show({
          severity: "success",
          summary: "Успех",
          detail: "Заказ успешно создан",
          life: 3000,
        });
        setDialogVisible(true);
      }
    } catch (error) {
      console.error("Ошибка при создании/обновлении заказа:", error);
      toast.current.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Произошла ошибка при отправке заказа",
        life: 5000,
      });
    }
  };

  const handleCancelOrder = async () => {
    try {
      console.log(orderId);
      await removeOrder(orderId).unwrap();
      toast.current.show({
        severity: "success",
        summary: "Успех",
        detail: "Заказ успешно отменен",
        life: 3000,
      });
      setTimeout(() => {
        navigate("/profile/orders");
      }, 3000);
    } catch (error) {
      console.error("Ошибка при отмене заказа:", error);
      toast.current.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Произошла ошибка при отмене заказа",
        life: 5000,
      });
    }
  };

  function addCorpPerson() {
    if (selectedTicket === "Персональный билет") {
      return (
        <UserOrderTemplate
          user={form}
          editDisabled={!isEditable}
          disablePromo={orderId != null}
          onChange={handleUserFormChange}
        />
      );
    }
    if (selectedTicket === "Корпоративный заказ") {
      return (
        <div>
          {corpOrder.map((item, index) => (
            <div className={styles.corp} key={index}>
              <div className={styles.corp_person}>
                <h4>Участник №{index + 1}</h4>
                {isEditable && (
                  <Button
                    label="Удалить"
                    icon="pi pi-trash"
                    className={styles.delete_person}
                    onClick={() =>
                      setCorpOrder((corpOrder) =>
                        corpOrder.filter((itm, id) => id !== index)
                      )
                    }
                  />
                )}
              </div>

              <UserOrderTemplate
                user={item}
                editDisabled={!isEditable}
                disablePromo={orderId != null}
                onChange={(updatedData) =>
                  handleCorpOrderChange(index, updatedData)
                }
              />
            </div>
          ))}

          {isEditable && (
            <Button
              label="Добавить участника"
              icon="pi pi-plus"
              className={styles.add_person}
              onClick={() => setCorpOrder([...corpOrder, { ...form }])}
            />
          )}
        </div>
      );
    }
  }

  const renderCancelButton = () => {
    if (orderData?.status === "SENT") {
      return (
        <Button
          label="Отменить"
          className={styles.cancel_btn}
          onClick={handleCancelOrder}
          disabled={isRemoving}
        />
      );
    } else if (orderData?.status === "PROCESSING") {
      return (
        <Button
          label="Отменить"
          className={styles.cancel_btn}
          disabled
          tooltip="Заказ находится в обработке и не может быть отменен"
        />
      );
    }
    return null;
  };

  if (isLoading) return <p>Загрузка...</p>;

  const renderDialogFooter = () => (
    <div className={styles.btn_wrapper}>
      <Button
        label="Ок"
        icon="pi pi-check"
        onClick={() => navigate("/profile/orders")}
        className={styles.btn}
      />
    </div>
  );

  return (
    <div className={styles.container}>
      <Toast ref={toast} />
      <div className={styles.header}>
        <Button
          label="Назад"
          icon="pi pi-angle-left"
          className={styles.go_back_btn}
          onClick={() => navigate("/profile/orders")}
        />
      </div>
      <h2>{editOrder ? "Редактирование заказа" : "Создание заказа"}</h2>

      <div className={styles.content}>
        <div className={styles.item}>
          <div className={styles.column}>
            <label id="ticket">Тип билета</label>
            <Dropdown
              aria-labelledby="ticket"
              value={selectedTicket}
              onChange={(e) => setSelectedTicket(e.value)}
              options={["Персональный билет", "Корпоративный заказ"]}
              placeholder="Выберите тип билета"
              className={styles.dropdown_text}
              disabled={editOrder}
            />
          </div>
        </div>
        {addCorpPerson()}
      </div>

      {totalPrice != 0 ? (
        <div className={styles.totalPriceContainer}>
          <h3>Всего: {totalPrice} ₽</h3>
        </div>
      ) : (
        ""
      )}

      {orderData == null ? null : <OrderFileTable orderId={orderId} />}

      <div className={styles.actions}>
        <Button
          label={editOrder ? "Обновить" : "Отправить"}
          className={styles.sent_btn}
          onClick={handleSubmit}
          //onClick={() => setDialogVisible(true)}
          disabled={isCreating || isUpdating || !isEditable}
        />
        {renderCancelButton()}
      </div>
      {/* Диалоговое окно */}
      <Dialog
        header="Счет сформирован"
        headerClassName={styles.dialog_header}
        visible={dialogVisible}
        className={styles.dialog}
        footer={renderDialogFooter}
        onHide={() => setDialogVisible(false)}
      >
        <div className={styles.dialog_content}>
          <h3>Спасибо за регистрацию и приобретение билета!</h3>
          <p>Наши менеджеры свяжутся с вами в течение дня.</p>
          <p>
            Если у вас возникли трудности, воспользуйтесь{" "}
            <Link
              className={styles.link}
              to="/documents/инструкция_для_регистрации_участника_на_форум_через_сайт_gradforum.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              информацией с инструкции.
            </Link>
          </p>
        </div>
      </Dialog>
    </div>
  );
}
