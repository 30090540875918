export const Speakers = [
  [
    [
      {
        id: 1,
        name: "Константин",
        surname: "Михайлик",
        descr:
          "Заместитель министра строительства и жилищно-коммунального хозяйства Российской Федерации",
        info: "",
        img: "mihailik.png",
        imgMobile: "mihailik-mobile.png",
      },
      {
        id: 2,
        name: "Александр",
        surname: "Моор",
        descr: "Губернатор Тюменской области",
        info: "",
        img: "moor.png",
        imgMobile: "moor-mobile.png",
      },
    ],
    [
      {
        id: 3,
        name: "Сергей",
        surname: "Шустов",
        descr: "Заместитель Губернатора Тюменской области",
        info: "",
        img: "shustov.png",
        imgMobile: "shustov-mobile.png",
      },
      {
        id: 4,
        name: "Павел",
        surname: "Перевалов",
        descr: "Заместитель Губернатора Тюменской области",
        info: "",
        img: "perevalov.png",
        imgMobile: "perevalov-mobile.png",
      },
    ],
  ],
  [
    [      {
        id: 5,
        name: "Валерий",
        surname: "Киселев",
        descr: "Временно исполняющий обязанности начальника Главного управления строительства Тюменской области",
        info: "",
        img: "kiselev.png",
        imgMobile: "kiselev-mobile.png",
      },
      {
        id: 6,
        name: "Елена",
        surname: "Звонарёва",
        descr:
          "Заместитель руководителя Центра компетенций Департамента строительства Москвы, советник Министра строительства РФ",
        info: "",
        img: "zvonareva.png",
        imgMobile: "zvonareva-mobile.png",
      },
    ],
    [
      {
        id: 7,
        name: "Максим",
        surname: "Афанасьев",
        descr: "Глава города Тюмени",
        info: "",
        img: "afanasev.png",
        imgMobile: "afanasev-mobile.png",
      },
      {
        id: 8,
        name: "Виктор",
        surname: "Рейн",
        descr:
          "Заместитель председателя Комитета по экономической политике и природо-пользованию Тюменской областной Думы",
        info: "",
        img: "rhein.png",
        imgMobile: "rhein-mobile.png",
      },
    ],
  ],
  [
    [
      {
        id: 9,
        name: "Дмитрий",
        surname: "Трофимов",
        descr:
          "Управляющий директор департамента развития корпоративного бизнеса, ПАО Сбербанк",
        info: "",
        img: "trofimov.png",
        imgMobile: "trofimov-mobile.png",
      },
      {
        id: 10,
        name: "Дмитрий",
        surname: "Засыпкин",
        descr:
          "Инженер-проектировщик, управляющий партнер проектной компании «Инженерная группа-34»",
        info: "",
        img: "zasipkin.png",
        imgMobile: "zasipkin-mobile.png",
      },
    ],
    [
      {
        id: 11,
        name: "Сергей",
        surname: "Козлов",
        descr: "Руководитель Центра урбанистики ТюмГУ",
        info: "",
        img: "kozlov.png",
        imgMobile: "kozlov-mobile.png",
      },
      {
        id: 12,
        name: "Михаил",
        surname: "Зайчук",
        descr:
          "Руководитель архитектурной группы rTIM, Rocket Group, Выпускник программы Архитекторы.рф",
        info: "",
        img: "zaichuk.png",
        imgMobile: "zaichuk-mobile.png",
      },
    ],
  ],
  [
    [
      {
        id: 13,
        name: "Алексей",
        surname: "Комов",
        descr: "Заместитель Городского Головы, главный архитектор Калуги",
        info: "",
        img: "komov.png",
        imgMobile: "komov-mobile.png",
      },
      {
        id: 14,
        name: "Сергей",
        surname: "Разуваев",
        descr: "Директор консалтингового агентства «GMK»",
        info: "",
        img: "razy.png",
        imgMobile: "razy-mobile.png",
      },
    ],
    [
      {
        id: 15,
        name: "Ольга",
        surname: "Романец",
        descr:
          "Член правления Ассоциации индустриальных парков России, директор по развитию i.moscow, Правительство Москвы",
        info: "",
        img: "romanets.png",
        imgMobile: "romanets-mobile.png",
      },
      {
        id: 16,
        name: "Анатолий",
        surname: "Азизов",
        descr:
          "Управляющий директор АО «ДОМ.РФ»",
        info: "",
        img: "azizov.png",
        imgMobile: "azizov-mobile.png",
      },
    ],
  ],
  [
    [
      {
        id: 17,
        name: "Михаил",
        surname: "Лабудин",
        descr: "Директор Ассоциации кластеров, технопарков и 0Э3 России",
        info: "",
        img: "labudin.png",
        imgMobile: "labudin-mobile.png",
      },
      {
        id: 18,
        name: "Алексей",
        surname: "Круковский",
        descr: "Директор ООО «Брусника. Строительство и девелопмент»",
        info: "",
        img: "kukovski.png",
        imgMobile: "kukovski-mobile.png",
      },
    ],
    [
      {
        id: 19,
        name: "Александр",
        surname: "Прыгунков",
        descr:
          "Член Совета директоров, управляющий партнер ПАО ГК «Самолет»",
        info: "",
        img: "prigunkov.png",
        imgMobile: "prigunkov-mobile.png",
      },
         {
        id: 20,
        name: "Гай",
        surname: "Имз",
        descr:
          "Председатель совета по экологическому строительству RuGBC",
        info: "",
        img: "gaimz.png",
        imgMobile: "gaimz-mobile.png",
      }
    ],
  ],
];
