import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import arrow from "./link-arrow-blue.svg";
import arrowHover from "./link-arrow-white.svg";
import styles from "./ParticipationFormatCard.module.css";

export default function ParticipationFormatCard({ format }) {
  const [hover, setHover] = useState(false);
  const formatName = format === "vip" ? "вип" : "бизнес";
  const navigate = useNavigate();

  return (
    <div
      className={styles.card}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => {
        navigate("./opportunities");
      }}
    >
      <div className={styles.card_text}>
        <div className={styles.format_name}>{formatName}</div>
        <div className={styles.learn_more}>
          <Link>Узнать больше</Link>
          <img src={hover ? arrowHover : arrow} />
        </div>
      </div>

      <div
        className={
          format === "vip" ? styles.side_text_vip : styles.side_text_business
        }
      >
        <span className={format === "vip" ? styles.vip : styles.business}>
          {format}
        </span>
      </div>
    </div>
  );
}
