import styles from "./useCheckStatus.module.css";

export default function getStatusLabel(status) {
  switch (status) {
    case "SUBMITED":
      return <span className={styles.done}>Регистрация завершена успешно</span>;
    case "PROCESSING":
      return <span className={styles.processing}>На рассмотрении</span>;
    case "REJECTED":
      return <span className={styles.rejected}>Отклонен</span>;
    case "SENT":
      return <span className={styles.sent}>Отправлен</span>;
    case "CANCELED":
      return <span className={styles.cancel}>Отменен</span>;
    default:
      return <span className={styles.sent}>{status}</span>;
  }
}
