import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { apiSlice } from "./slices/apiSlice.js";
import endpointsReducer from "./slices/endpointsSlice.js";
import userSlice from "./slices/userSlice.js";

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  endpoints: endpointsReducer,
  user: userSlice,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware),
});

export default store;
